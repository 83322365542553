import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Link from '../components/Link';
import GLOBAL_MEDIA_QUERIES from '../constants/media-queries';

export default function Home({ data }) {
  const sources = {
    conte: [
      data.mobileConte.childImageSharp.fluid,
      {
        ...data.tabletConte.childImageSharp.fluid,
        media: GLOBAL_MEDIA_QUERIES.tablet,
      },
      {
        ...data.desktopConte.childImageSharp.fluid,
        media: GLOBAL_MEDIA_QUERIES.desktop,
      },
    ],
    pedagogique: [
      data.mobilePedagogique.childImageSharp.fluid,
      {
        ...data.tabletPedagogique.childImageSharp.fluid,
        media: GLOBAL_MEDIA_QUERIES.tablet,
      },
      {
        ...data.desktopPedagogique.childImageSharp.fluid,
        media: GLOBAL_MEDIA_QUERIES.desktop,
      },
    ],
    ressources: [
      data.mobileRessources.childImageSharp.fluid,
      {
        ...data.tabletRessources.childImageSharp.fluid,
        media: GLOBAL_MEDIA_QUERIES.tablet,
      },
      {
        ...data.desktopRessources.childImageSharp.fluid,
        media: GLOBAL_MEDIA_QUERIES.desktop,
      },
    ],
    audio: [
      data.mobileAudio.childImageSharp.fluid,
      {
        ...data.tabletAudio.childImageSharp.fluid,
        media: GLOBAL_MEDIA_QUERIES.tablet,
      },
      {
        ...data.desktopAudio.childImageSharp.fluid,
        media: GLOBAL_MEDIA_QUERIES.desktop,
      },
    ],
  }
  return <div className="container">
    <div className="grid grid-tablet">
      <div className="column-half">
        <Link to="/conte">
          <Img fluid={sources.conte} />
          <span className="name">Le conte</span>
        </Link>
      </div>
      <div className="column-half">
        <Link to="/pedagogique">
          <Img fluid={sources.pedagogique} />
          <span className="name">Les contenus<br />pédagogiques</span>
        </Link>
      </div>
      <div className="column-half">
        <Link to="/ressources">
          <Img fluid={sources.ressources} />
          <span className="name">Les ressources</span>
        </Link>
      </div>
      <div className="column-half">
        <Link to="/audio">
          <Img fluid={sources.audio} />
          <span className="name">Les contenus<br />audios</span>
        </Link>
      </div>
    </div>
  </div>
}

export const query = graphql`
query {
  mobileConte: file(relativePath: { eq: "home/conte-mobile.png" }) {
    childImageSharp {
      fluid(maxWidth: 480, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  tabletConte: file(relativePath: { eq: "home/conte-tablet.png" }) {
    childImageSharp {
      fluid(maxWidth: 480, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  desktopConte: file(relativePath: { eq: "home/conte.png" }) {
    childImageSharp {
      fluid(maxWidth: 540, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  mobilePedagogique: file(relativePath: { eq: "home/pedagogique-mobile.png" }) {
    childImageSharp {
      fluid(maxWidth: 480, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  tabletPedagogique: file(relativePath: { eq: "home/pedagogique-tablet.png" }) {
    childImageSharp {
      fluid(maxWidth: 480, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  desktopPedagogique: file(relativePath: { eq: "home/pedagogique.png" }) {
    childImageSharp {
      fluid(maxWidth: 540, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  mobileRessources: file(relativePath: { eq: "home/ressources-mobile.png" }) {
    childImageSharp {
      fluid(maxWidth: 480, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  tabletRessources: file(relativePath: { eq: "home/ressources-tablet.png" }) {
    childImageSharp {
      fluid(maxWidth: 480, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  desktopRessources: file(relativePath: { eq: "home/ressources.png" }) {
    childImageSharp {
      fluid(maxWidth: 540, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  mobileAudio: file(relativePath: { eq: "home/audio-mobile.png" }) {
    childImageSharp {
      fluid(maxWidth: 480, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  tabletAudio: file(relativePath: { eq: "home/audio-tablet.png" }) {
    childImageSharp {
      fluid(maxWidth: 480, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  desktopAudio: file(relativePath: { eq: "home/audio.png" }) {
    childImageSharp {
      fluid(maxWidth: 540, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`;
